<template>
  <div id="app">

    <div v-if="updated_service_worker" class="banner-loading text-center">
      <h1><i class="far fa-fw fa-pulse fa-spinner"></i></h1>
      <p class="sm snug">Please wait. Loading updates. The app will refresh in a moment to apply updates.</p>
    </div> <!-- /.banner-loading -->

    <div v-if="(standaloneDisplayModeAndroid || standaloneDisplayModeIOS) && user_props && !user_props.device_token && !user_props.push_notifications_denied && !hideNotificationsBanner && supportsNotifications" class="banner-notifications d-flex justify-content-between">
      <p class="snug">
        <strong>Get Push Notifications</strong><br>
        <small>stay connected with your team</small>
      </p>
      <p class="flex-grow-1 snug text-right"><button @click.prevent="requestNotifications" class="btn btn-app btn-sm btn-push">Enable</button></p>
      <p class="snug text-right"><button @click="hideNotificationsBanner=true" class="btn btn-light btn-sm btn-push-close"><i class="far fa-times"></i></button></p>
    </div> <!-- /.banner-notifications -->

    <div v-if="!hidePushBanner && pushMessage" class="banner-push-message">
      <button @click="hidePushBanner=true" class="btn btn-icon btn-close"><i class="far fa-times"></i></button>
      <router-link to="/" @click.native="hidePushBanner=true" class="message-link d-flex justify-content-left align-items-center">
        <img src="./assets/img/icon-smiley-lg@2x.png" alt="" width="30px" height="30px">
        <p class="snug">
          <span v-if="pushMessage.title"><strong>{{ pushMessage.title }}</strong></span><br>
          <span v-if="pushMessage.body">{{ pushMessage.body }}</span>
        </p>
      </router-link>
    </div>
    <!-- /.banner-push-message -->

    <div v-if="pointChange" class="banner-point-change">
      <p class="snug"><em><img src="./assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /> You earned <strong>{{ pointChange }} Smileys!</strong></em></p>
    </div> <!-- /.banner-point-change-->

    <div v-if="!standaloneDisplayModeIOS && !hidePwaInstallBanner" class="banner-install text-center">
      <div v-if="isIos && isIosSafari" class="banner-install-ios">
        <button @click="hidePwaInstallBanner=true" class="btn btn-icon btn-close"><i class="far fa-times"></i></button>
        <h3>Install Smiley to your Apple device</h3>
        <p class="snug">Tap <img src="./assets/img/sf-symbols-square.and.arrow.up.svg" width="16px" height="21px" alt="the share icon" /> then <strong>Add to Home Screen</strong> <img src="./assets/img/sf-symbols-plus.app.svg" width="16px" height="16px" alt="" /></p>
      </div>
      <div v-if="isIos && !isIosSafari" class="banner-install-ios">
        <button @click="hidePwaInstallBanner=true" class="btn btn-icon btn-close"><i class="far fa-times"></i></button>
        <h3>Install Smiley to your Apple device</h3>
        <p class="snug">Please open Smiley in Safari to continue. Other browsers don't support installing apps on iOS.</p>
      </div>
    </div> <!-- /.banner-install -->

    <router-view :customer="customer" />
  </div>
</template>

<script>
import Vue from 'vue'
import Firebase from 'firebase/compat/app'
import {mapGetters} from 'vuex'
import store from './store'
import moment from 'moment'

export default {
  data () {
    return {
      customer: null,
      hidePwaInstallBanner: false,
      loadingAppUpdates: false,
      findingRegistrationWaiting: false,
      findingInterval: null,
      hideNotificationsBanner: false,
      hidePushBanner: true,
      pushMessage: null,
      pointChange: null,
      pointChangeTimeOut: null,
      // testing
      // ---
      // pushMessage: {
      //   title: 'New Message in Smiley',
      //   body: 'Reopen the activity tab to view'
      // },
      // hidePushBanner: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims', 'updated_service_worker']),
    // show add to home screen modal when appropriate
    standaloneDisplayModeIOS() {
      return ('standalone' in window.navigator) && (window.navigator.standalone);
    },
    standaloneDisplayModeAndroid() {
      return (window.matchMedia('(display-mode: standalone)').matches);
    },
    isIos() {
      const ua = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(ua);
    },
    isIosSafari() {
      const ua = window.navigator.userAgent.toLowerCase();
      // return if CriOS for chrome & fxios for firefox
      // return !ua.match(/crios/i) && !ua.match(/fxios/i);
      return !/crios/.test(ua) && !/fxios/.test(ua);
    },
    // check for notifications support
      // https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
    supportsNotifications() {
      if (!("Notification" in window)) {
        return false;
      }
      return true;
    },
  },
  watch: {
    user_props(newProps, oldProps) {
      // console.log('oldProps',oldProps);
      // console.log('newProps',newProps);
      if(newProps) {
        // load custom claims
        if((!oldProps || newProps.refreshTime !== oldProps.refreshTime) && newProps.refreshTime > this.user.metadata.lastLoginAt) {
          // if refreshTime when user_props changed, get the token and update in state
          this.getCustomClaims();
        }
        // check if current token is still valid
        if(Notification.permission == 'granted' && (!oldProps || oldProps.device_token == newProps.device_token)) {
          // console.log('old token',oldProps.device_token);
          // console.log('new token',newProps.device_token);
          // update the token only if refresh date was in the past
            // stops infinite loop of refreshing token every time points or unread_messages is updated
          const dateCode = new Date().toISOString().slice(0, 10).replaceAll('-', '');
          // console.log('dateCode',dateCode)
          if((oldProps && !oldProps.last_token_refresh) || (newProps.last_token_refresh && newProps.last_token_refresh < dateCode)) {
            console.log('checking for token update')
            this.handlePermission('granted');
          }
        }
        // if not permission but have a token, delete it because app was reinstalled
        if(Notification.permission !== 'granted' && this.user_props.device_token) {
          var batch = Vue.firestore.batch();
          const update = {
            device_token: null,
          };
          const userRef = Vue.firestore.doc(`customers/${this.custom_claims.customerId}/users/${this.user.uid}`);
          batch.update(userRef, update);
          const userPropsRef = Vue.firestore.doc(`users/${this.user.uid}`);
          batch.update(userPropsRef, update);
          batch.commit().then(() => {
            // console.log('old device token removed')
          })
          .catch(err => console.log(err));
        }
        // if props doesn't have a points property, set it to zero
        if(!newProps.points && newProps.points !== 0) {
          // console.log('starting points at zero')
          Vue.firestore.collection('users').doc(this.user.uid)
            .update({
              points: 0,
            })
            .catch(err => console.log(err));
        }
        // if points increased, show rewards banner
        if(oldProps && newProps.points && oldProps.points && newProps.points > oldProps.points) {
          // use timeout to hide banner again
          if(this.pointChangeTimeOut) {
            // if timeout exists when another award happens, adjust value, then reset it
            window.clearTimeout(this.pointChangeTimeOut);
            this.pointChange += newProps.points - oldProps.points;
          } else {
            this.pointChange = newProps.points - oldProps.points;
          }
          this.pointChangeTimeOut = window.setTimeout(() => {
            this.pointChange = null;
            window.clearTimeout(this.pointChangeTimeOut);
          }, 3000)
        }
        // if unread_messages removed, award points
        if(oldProps && oldProps.unread_messages && oldProps.unread_messages.length && (!newProps.unread_messages || !newProps.unread_messages.length)) {
          // if the last date awarded points wasn't today
          const dateCode = new Date().toISOString().slice(0, 10).replaceAll('-', '');
          if(!newProps.points_caughtup_as_of || newProps.points_caughtup_as_of !== dateCode) {
            // use timeOut to avoid incrementing multiple times if other changes are pending
            window.setTimeout(() => {
              Vue.firestore.collection('users')
                .doc(this.user.uid)
                .update({
                  // do not use FieldValue.increment, instead do math manually
                  // points: Firebase.firestore.FieldValue.increment(10),
                  points: newProps.points ? newProps.points+10 : 10,
                  // save datecode to prevent awarding more than once a day
                  points_caughtup_as_of: dateCode,
                });
            }, 3000);
          }
        }
        // if no unread_messages in old or new props, update last caught up date
        // console.log('newProps',newProps);
        // console.log('oldProps',oldProps);
        const dateCode = new Date().toISOString().slice(0, 10).replaceAll('-', '');
        if(this.custom_claims && this.custom_claims.customerId && newProps && (!oldProps || !oldProps.unread_messages || !oldProps.unread_messages.length) && (!newProps.unread_messages || !newProps.unread_messages.length) && (!newProps.caught_up_as_of || newProps.caught_up_as_of !== dateCode)) {
          // console.log('no unread messages');
          // open batch
          var batch = Vue.firestore.batch();
          // update user props
          const userPropsRef = Vue.firestore.doc(`users/${this.user.uid}`)
          batch.update(userPropsRef, {
            caught_up_as_of: dateCode,
          });
          // update customer users collection
          const userCustRef = Vue.firestore.doc(`customers/${this.custom_claims.customerId}/users/${this.user.uid}`);
          batch.update(userCustRef, {
            caught_up_as_of: dateCode,
          });
          // commit batch
          batch.commit()
            .then(() => {
              console.log('updated caught_up_as_of for no unread messages');
            })
            .catch(err => console.error(err));
        }
      }
    },
    custom_claims() {
      // console.log('claims loaded')
      this.getCustomer();
      // if custom claims changed to hidden status, force log out
      if(this.custom_claims && this.custom_claims.hidden) {
        this.$firebase.logOut();
      }
    },
  },
  methods: {
    async getCustomClaims() {
      const token = await Vue.auth.currentUser.getIdToken(true);
      const decodedToken = await Vue.auth.currentUser.getIdTokenResult();
      store.dispatch('updateCustomClaims', decodedToken.claims);
      // console.log('auth token refreshed', decodedToken);
    },
    getCustomer() {
      // if has admin custom claims, get the customerId for that
        // instead of using the user id

      // exit if no custom_claims
      // console.log(this.custom_claims)
      if(!this.custom_claims) {
        // console.log('exit: no custom claims loaded');
        return;
      }
      let customerId;
      // account owner: if uid matches claims customerId, use that customerId
      if(this.custom_claims.customerId && this.custom_claims.customerId == this.user.uid) {
        customerId = this.custom_claims.customerId;
      }
      // delegated super user: if custom claims is a super admin, use that customerId
      else if(this.custom_claims.adminLevel == "superAdmin" && this.custom_claims.customerId) {
        customerId = this.custom_claims.customerId;
      }
      // // else, exit, don't load customer
      else {
        // console.log('exit: no permission to load customer');
        return;
      }
      // console.log({customerId});
      Vue.firestore.collection('customers')
        .doc(customerId)
        .onSnapshot((doc) => {
          if(doc.exists) {
            this.customer = {
              ...doc.data(),
              id: doc.id,
            };
            // console.log("LayoutPrivate Customer", this.customer);
          } else {
            this.customer = false;
            console.log('customer document not found')
          }
        });
    },
    // must request notifications on button click or most browsers will automatically block it
    requestNotifications() {
      console.log('Requesting push notifications permission...');
      Notification.requestPermission().then((permission) => {
        this.handlePermission(permission);
      })
      .catch(err => console.error(err));
    },
    handlePermission(permission) {
      if(!this.custom_claims || !this.custom_claims.customerId) {
        return;
      }
      // console.log('permission:',permission);
      const publicMessagingKey = 'BLQ1hd6X7yn2TIO0k9Xk7BZQEtuAhMVbghWWAEUqQkayTjxMhSfouX-if6k4hh5ayPDuYPZViX0jnVvqBl2P0o0';
      var batch = Vue.firestore.batch();
      if (permission === 'granted') {
        // console.log('Notification permission granted.');
        // save device token on user_props
        Vue.messaging.getToken({ vapidKey: publicMessagingKey }).then((currentToken) => {
          if(currentToken) {
            // console.log('currentToken',currentToken);
            // exit if same token already stored
            if(this.user_props && this.user_props.device_token && this.user_props.device_token == currentToken) {
              // console.log('Device token already stored.');
              return true;
            }
            // store datecode to only refresh token once per day
            const dateCode = new Date().toISOString().slice(0, 10).replaceAll('-', '');
            const update = {
              device_token: currentToken,
              last_token_refresh: dateCode,
              last_token_refresh_timestamp: moment().unix(),
            };
            const userRef = Vue.firestore.doc(`customers/${this.custom_claims.customerId}/users/${this.user.uid}`);
            batch.update(userRef, update);
            const userPropsRef = Vue.firestore.doc(`users/${this.user.uid}`);
            batch.update(userPropsRef, update);
            batch.commit().then(() => {
              this.hideNotificationsBanner = true;
            })
            .catch(err => console.log(err));
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            this.hideNotificationsBanner = true;
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          this.hideNotificationsBanner = true;
        });
        
      } else {
        console.log('Notification permission denied.');
        this.hideNotificationsBanner = true;
        // TODO: add another check to see if the popup just didn't show on unsupported device?
        // TODO: save push_notifications_denied=true on user_props
      }
    },
    clearBadge(e) {
      navigator.clearAppBadge();
    },
  },
  mounted () {
    // get user's custom claims
    if(this.user) {
      this.getCustomClaims();
      // this.getCustomer(); // not here, only get after custom_claims is set
    }
    // listen for push messages while open
      // works in Android, but not iOS yet
    Vue.messaging.onMessage((payload) => {
      console.log('[App.vue] received message payload', payload);
      this.pushMessage = null;
      // only show if there is a notification in payload
      if(payload && payload.notification) {
        this.pushMessage = payload.notification;
        this.hidePushBanner = false;
      }
    });
    // reset app badge
      // calling here only works when re-opening app after it has been force closed
      // calling in beforeUpdate() & updated() just clears right after it is set
    navigator.clearAppBadge();
    // clears when app is reopened
    window.addEventListener('focus', this.clearBadge);
  },
  // use updated hook for sw update checks
    // mounted does not fire often enough
    // updated fires on most bottom tab navigation
  updated() {
    // if no last_sw_refresh or it's been more than an hour, try to update serviceworker
    // const oneHourAgo = new Date().getTime() - (60*60*1000); // 1hr in ms = 1hr * 60m * 60s * 1000ms
    const oneHourAgo = moment().subtract(1,'hours').unix();
    // testing one minute instead of one hour
    // const oneHourAgo = new Date().getTime() - (60*1000); // 1min in ms = 1m * 60s * 1000ms
    if(this.user_props) {
      console.log(`check for sw update - oneHourAgo: ${moment.unix(oneHourAgo).format("MMM Do, YYYY, h:mm:ssa Z")}, last_sw_refresh: ${moment.unix(this.user_props.last_sw_refresh).format("MMM Do, YYYY, h:mm:ssa Z")}`);
    }
    if(this.user_props && (!this.user_props.last_sw_refresh || this.user_props.last_sw_refresh < oneHourAgo)) {
      console.log('attempting to update sw in App.vue')
      // get serviceworker
      navigator.serviceWorker.ready.then(registration => {
        // console.log('serviceWorker ready')
        // attempt to update serviceworker
        registration.update().then((reg) => {
          console.log('serviceworker registration checked in App.vue', reg)
          // if no waiting reg, save last sw update timestamp
            // only save when no waiting reg, if try to save with waiting reg, app will refresh before
            // last_sw_refresh gets saved
          if(!reg.waiting) {
            store.dispatch('updatedServiceWorker', false);
            console.log('no waiting sw registration');
            Vue.firestore.doc(`users/${this.user.uid}`)
              .update({
                last_sw_refresh: moment().unix(),
                // last_sw_refresh: new Date().getTime(), // update as time now
              })
              .then(() => {
                console.log('last_sw_refresh updated');
              })
              .catch(err => console.log(err)); 
          }
        })
        .catch(err => console.log(err));
      });
    } else {
      console.log('skip sw update in App.vue, last update within past hour');
    }
  },
}
</script>

<style lang="less">
@import "assets/less/style.less";

#app {
  height: 100%;
}
.banner-point-change {
  position: fixed;
  width: 100%;
  bottom: 83px;
  // height: 94px;
  z-index: 8999;
  padding: 15px;
  border-top: 1px solid rgba(255,255,255,0.3);
  background-color: fadeout(@light-color, 5%);
  box-shadow: 0 0 50px 0 fadeout(@major-color, 50%);
}
.banner-install {
  .banner-install-ios {
    position: fixed;
    width: 100%;
    bottom: 0;
    // height: 94px;
    z-index: 9000;
    padding: 35px 15px;
    background-color: @color-blue;
    box-shadow: 0 0 40px rgba(0,0,0,0.1);
    border-top: 1px solid rgba(255,255,255,0.3);

    h3 {
      margin: 0 0 10px;
    }
    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  // move to top on ipads
  @media (min-width: @screen-sm-min) {
    .banner-install-ios {
      bottom: auto;
      top: 0;
    }
  }
}

.banner-loading {
  position: absolute;
  width: 100%;
  // height: 100%;
  z-index: 9005;
  background-color: @color-blue;
  padding: 35px 15px;
}

.banner-notifications {
  position: absolute;
  width: 100%;
  // height: 100%;
  z-index: 9001;
  background-color: @dark-color;
  padding: 20px;
  color: @light-color;

  p {
    line-height: 1em;
  }
  .btn-push {
    border-radius: 3px;
    // background-color: fadeout(@dark-color, 50%);
    padding: 10px 12px 12px !important;

    &:hover {
      color: @dark-color;
      background-color: @light-color;
    }
  }
  .btn-push-close {
    border-radius: 3px;
    padding: 10px 12px 12px !important;
    background-color: fadeout(@light-color, 75%);
    margin-left: 5px;

    .far, .fas {
      margin: 0;
    }
    &:hover {
      background-color: @light-color;
    }
  }
}
.banner-push-message {
  position: absolute;
  width: 100%;
  // height: 100%;
  z-index: 9001;
  background-color: @color-blue;
  padding: 10px;

  a, p {
    text-decoration: none;
  }
  a, a:hover, a:active, a:focus {
    display: block;
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 5px;
    color: @dark-color;
  }
  .btn-close {
    position: absolute;
    top: 15px;
    right: 10px;
  }
  .message-link {
    img {
      margin-right: 10px;
    }
    p {
      line-height: 1.2em;
    }
  }
}
</style>
