<template>
  <layout-private :customer="customer" :pageTitle="'Profile'">
    <div class="page">

      <div v-if="user_props && custom_claims && providerUser" class="provider-stats">
        <p class="subtitle"><strong>Provider Stats</strong></p>
        <stats-prov-care-progress :providerUid="user.uid" :providerUser="providerUser"></stats-prov-care-progress>
      </div>

      <div>
        <h3>Display Name &amp; Photo</h3>
        <p>How do you want your name and photo to appear to others?</p>
        <div class="row">
          <div class="col-12 col-photo-container">
            <div class="photo-container d-flex flex-column align-items-center justify-content-center text-center">
              <p v-if="!profilePhotoURL && !imagePreviewSrc" class="snug w-100">
                <i class="far fa-user fa-3x mb-2"></i>
              </p>
              <p v-if="user_props && user_props.profilePhotoStoragePath && !profilePhotoURL"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
              <div v-if="profilePhotoURL && !imagePreviewSrc" class="img-profile-container">
                <badge v-if="activeBadge && profilePhotoURL" :badge="activeBadge" class="badge-overlay  badge-overlay-md" :size="'sm'" :disableTooltip="true"></badge>
                <div
                  class="img-profile img-profile-lg"
                  :class="{'img-badge': activeBadge}"
                  :style="{'border-color': activeBadge ? activeBadge.badge_color : 'transparent'}"
                >
                  <img :src="profilePhotoURL" class="img-fluid" alt="profile photo" />
                </div>
              </div>
              <div v-if="imagePreviewSrc" class="img-profile-container photo-preview">
                <badge v-if="activeBadge && profilePhotoURL" :badge="activeBadge" class="badge-overlay  badge-overlay-md" :size="'sm'" :disableTooltip="true"></badge>
                <button @click="resetFileInput" class="btn btn-remove"><i class="far fa-fw fa-times"></i></button>
                <div class="img-profile img-profile-lg">
                  <img :src="imagePreviewSrc" class="img-fluid" alt="profile photo" />
                </div>
              </div>
              <p class="snug w-100 text-center">
                <!-- <button class="btn btn-link" @click="uploadProfilePhoto">Upload New Photo</button> -->
                <input @change="previewFiles" ref="fileInput" class="form-control-file" placeholder="Upload New Photo" type="file" accept="image/*" />
              </p>
            </div>
          </div> <!-- /.col -->
          <div v-if="imagePreviewSrc" class="col-12"><p class="small"><em>Note: profile photos will automatically be cropped to a square.</em></p></div>
          <div v-if="profilePhotoURL && !imagePreviewSrc" class="col-12">
            <p>
              <label class="normal-weight form-check">
                <input type="checkbox" name="deletePhoto" v-model="deletePhoto" value="deletePhoto" class="form-check-input">
                <small>delete profile photo</small>
              </label>
            </p>
          </div>
          <div class="col-12">
            <input v-model="newDisplayName" class="form-control" id="displayName" type="text" name="displayName" placeholder="Enter display name" />
            <list-errors :errors="errorsDisplayName"></list-errors>
            <p v-if="savingUser"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
            <p v-if="userSaved"><i class="far fa-fw fa-check color-green"></i> Updates saved!</p>
            <p v-if="!savingUser" class="snug"><button class="btn btn-app" @click="updateNameAndPhoto">Save Changes<i class="far fa-angle-right fa-fw"></i></button></p>
          </div> <!-- /.col -->
        </div> <!-- /.row -->
      </div> <!-- /.col -->

      <!-- placeholder for change email address feature -->
      <div></div>

      <div v-if="!isGoogleAuthUser && custom_claims && custom_claims.adminLevel">
        <hr />
        <h3 v-if="custom_claims.adminLevel !== 'superAdmin'">Connect to Google</h3>
        <p v-if="custom_claims.adminLevel !== 'superAdmin'">Connect Smiley to your Google account to display your profile photo in Smiley. Note: your password will no longer work, and you'll only be able to log in with Google.</p>
        <h3 v-if="custom_claims.adminLevel == 'superAdmin'">Connect to Google Business</h3>
        <p v-if="custom_claims.adminLevel == 'superAdmin'">Smiley can notify your team about new reviews from patients on Google. Sign in with a Google Account that's an admin of your locations in Google Business. Then, head over to the Locations page to import your locations!</p>
        <list-errors :errors="errors"></list-errors>
        <p v-if="loading"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
        <p v-if="!loading"><button class="btn btn-app btn-google" @click="connectWithGoogle"><i class="fab fa-google fa-fw"></i>Connect to Google</button></p>
        <p v-if="googleLinkSuccessful">Your account was successfully connected to Google.</p>
      </div>
      
      <!-- placeholder for password reset feature -->
      <!-- <div><hr /></div> -->

      <div>
        <hr />
        <p><a @click.prevent="logOut" href="logOut" class="btn btn-link">Log Out</a></p>
      </div>

      <p class="sm color-dim"><em>Last Update: 3/24/25 2:37pm MDT</em></p>

    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import Badge from '../components/Badge.vue'
import StatsProvCareProgress from '../components/StatsProvCareProgress.vue'

export default {
  props: ['customer'],
  components: {
    LayoutPrivate, ListErrors, Badge, StatsProvCareProgress,
  },
  data () {
    return {
      profilePhotoURL: null,
      errors: [],
      loading: false,
      googleLinkSuccessful: null,
      newDisplayName: null,
      errorsDisplayName: [],
      savingUser: false,
      userSaved: false,
      imageFile: null,
      imagePreviewSrc: null,
      deletePhoto: false,
      badges: null,
      // customer/users object
      providerUser: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    isGoogleAuthUser () {
      if(this.user && this.user.providerData && this.user.providerData.some(profile => profile.providerId == "google.com")) {
        return true;
      }
      return false;
    },
    environment() {
      if(process.env.VUE_APP_ENV === "staging") {
        return "staging";
      }
      if(process.env.NODE_ENV === "production") {
        return "production";
      }
      if(process.env.NODE_ENV === "development" || process.env.VUE_APP_ENV === "test") {
        return "development";
      }
      return null;
    },
    activeBadge() {
      if(this.badges && this.badges.length && this.user_props.badge_level) {
        return this.badges.find(b => b.badge_level==this.user_props.badge_level);
      }
      return null;
    },
  },
  watch: {
    user (auth) {
      if(auth) {
        
      }
    },
    user_props() {
      // fill displayName box on page load
      if(this.user_props && this.user_props.name) {
        this.newDisplayName = this.user_props.name;
      }
      if(this.user_props.profilePhotoStoragePath) {
        this.loadUserPhoto();
      }
      if(this.user_props.badge_level || this.user_props.badge_level==0) {
        this.loadBadges();
      }
      // load customer/user object
      if(this.user_props && this.user_props.primary_location) {
        this.loadCustomerUser();
      }
    },
    userSaved() {
      if(this.userSaved && this.imageFile) {
        this.profilePhotoURL = null;
        this.resetFileInput();
        this.loadUserPhoto();
      }
    },
  },
  methods: {
    loadBadges() {
      // console.log('loadBadges')
      // load badges from firestore, save to this.badges, overwrite default badge
      if(this.custom_claims && this.custom_claims.customerId) {
        Vue.firestore.doc(`customers/${this.custom_claims.customerId}/settings/badges`)
        .get()
        .then(badgesSnap => {
          // console.log(badgesSnap.data());
          if(badgesSnap.exists && badgesSnap.data().badges_list && badgesSnap.data().badges_list.length) {
            this.badges = badgesSnap.data().badges_list;
          }
        })
        .catch(err => console.log(err));
      }
    },
    loadCustomerUser() {
      if(this.custom_claims && this.custom_claims.customerId) {
        Vue.firestore.doc(`customers/${this.custom_claims.customerId}/users/${this.user.uid}`)
        .get()
        .then(uSnap => {
          // console.log(uSnap.data());
          if(uSnap.exists && uSnap.data().primary_location && uSnap.data().provider_code) {
            this.providerUser = uSnap.data();
          }
        })
        .catch(err => console.log(err));
      }
    },
    updateNameAndPhoto() {
      if(!this.user || !this.user_props || !this.custom_claims || !this.custom_claims.customerId) {
        return;
      }
      this.errorsDisplayName = [];
      this.userSaved = false;
      this.savingUser = true;
      if(!this.newDisplayName || !this.newDisplayName.trim().length || this.newDisplayName.trim().length < 3) {
        this.errorsDisplayName.push('Please choose a display name longer than 3 characters.');
      }
      if(this.newDisplayName == this.user_props.name && !this.imageFile && (!this.deletePhoto || (this.deletePhoto && !this.user_props.profilePhotoStoragePath))) {
        this.errorsDisplayName.push("Oops, no changes to save.");
      }
      // allow only jpg, png, gif, webp, avif, tiff
      if(this.imageFile && this.imageFile.name) {
        let filenameSC = this.imageFile.name.toLowerCase();
        if( this.imageFile && (!filenameSC.includes('.jpg') && !filenameSC.includes('.jpeg') && !filenameSC.includes('.png') && !filenameSC.includes('.gif') && !filenameSC.includes('.webp') && !filenameSC.includes('.avif') && !filenameSC.includes('.tiff')) ) {
          this.errorsDisplayName.push('This image is not a valid filetype. Only jpg, png, gif, webp, avif, and tiff are allowed.')
        }
      }
      if(this.errorsDisplayName.length) {
        this.savingUser = false;
        return;
      }
      
      // upload image
      let storagePath;
      let uploadProm = Promise.resolve(true);
      if(this.user_props.profilePhotoStoragePath) {
        storagePath = this.user_props.profilePhotoStoragePath;
      }
      if(this.imageFile) {
        // storage path structure: customers/customer_id/users/uid.png
        // use UID as filename
        let fileName = this.user.uid;
        // console.log(`fileName`,fileName);
        let regex = /(?:\.([^.]+))?$/;
        // index 1 will return extension with no dot
        let fileExtension = regex.exec(this.imageFile.name)[1];
        // console.log(`fileExtension`,fileExtension);
        let newFileName = `${fileName}.${fileExtension}`;
        // console.log(`newFileName`,newFileName);
        storagePath = `customers/${this.custom_claims.customerId}/users/${newFileName}`;
        // set promise for upload
        let ref = Vue.storage.ref().child(storagePath);
        uploadProm = ref.put(this.imageFile);
      }

      // delete old photo if replaced or manually requested
      let delProm = Promise.resolve(true);
      if((this.deletePhoto && this.user_props.profilePhotoStoragePath) || (this.imageFile && this.user_props.profilePhotoStoragePath)) {
        const delRef = Vue.storage.ref().child(this.user_props.profilePhotoStoragePath);
        delProm = delRef.delete();
      }
      // overwrite storage path if asked to delete and no existing photo to upload
      if(this.deletePhoto && this.user_props.profilePhotoStoragePath && !this.imageFile) {
        storagePath = null;
      }
      // console.log(storagePath);
      // return;

      var batch = Vue.firestore.batch();

      // save on user props
        // must save photo storage path because file extension can vary
      let userPropsRef = Vue.firestore.collection('users')
        .doc(this.user.uid);
      batch.update(userPropsRef, {
        name: this.newDisplayName,
        // award points for changing name once
        points: this.user_props.points_name_change_awarded ? this.user_props.points : this.user_props.points+20,
        points_name_change_awarded: true,
        profilePhotoStoragePath: storagePath || null,
      });

      // save on customer users subcollection
        // must save photo storage path because file extension can vary
      let customerUserRef = Vue.firestore.collection('customers')
        .doc(this.custom_claims.customerId)
        .collection('users')
        .doc(this.user.uid);
      batch.update(customerUserRef, {
        name: this.newDisplayName,
        profilePhotoStoragePath: storagePath || null,
      });
      
      // upload image first
      uploadProm
        .catch(err => {
          this.errorsDisplayName.push(err.message);
          this.savingUser = false;
          return false;
        })
        // if new photo updated or manual delete request, delete old photo
        .then((success) => {
          if(success) {
            return delProm;
          }
          return true;
        })
        // save user data after photos
        .then(() => {
          // console.log('changed')
          return batch.commit();
        })
        .then(() => {
          this.savingUser = false;
          this.userSaved = true;
          if(this.deletePhoto) {
            this.deletePhoto = false;
            this.profilePhotoURL = null;
            this.resetFileInput();
          }
        })
        .catch((err) => {
          this.savingUser = false;
          this.errorsDisplayName.push(`An error occured: ${err.message}`);
          console.log(err);
        });
    },
    resetFileInput() {
      this.imagePreviewSrc = null;
      this.imageFile = null;
      // reset the file input's value since it cannot be bound in Vue
      this.$refs.fileInput.value = null;
    },
    previewFiles(event) {
      // console.log('event.target.files',event.target.files);
      this.imageFile = event.target.files[0];
      // read file so user can preview it and see as profile photo
      if(this.imageFile) {
        // console.log(this.imageFile);
        const reader = new FileReader();
        reader.addEventListener("load", ()=> {
          this.imagePreviewSrc = reader.result;
        });
        reader.readAsDataURL(this.imageFile);
      }
    },
    logOut () {
      this.$firebase.logOut()
    },
    loadUserPhoto () {
      if(!this.user_props || !this.user_props.profilePhotoStoragePath) {
        return;
      }
      Vue.storage.ref().child(this.user_props.profilePhotoStoragePath).getDownloadURL()
        .then((result) => {
          // only show if correct filetype
            // allow only jpg, png, gif, webp, avif, tiff
          if(result.includes('.jpg') || result.includes('.jpeg') || result.includes('.png') || result.includes('.gif') || result.includes('.webp') || result.includes('.avif') || result.includes('.tiff')) {
            this.profilePhotoURL = result;
          }
        })
        .catch(e => console.log(e));
    },
    async connectWithGoogle () {
      this.loading = true;
      // reset errors
      this.errors = [];
      if(!this.user) {
        this.errors.push("Something went wrong. You must be logged into Smiley first.")
      }
      let result = await Vue.auth.currentUser.linkWithPopup(Vue.googleAuth);
      if(result && result.code) {
        this.loading = false;
        this.errors.push(result.code);
      } else {
        this.googleLinkSuccessful = true;
      }
    },
  },
  async mounted () {
    if(this.user_props) {
      // fill displayName box on navigate
      if(this.user_props.name && !this.newDisplayName) {
        this.newDisplayName = this.user_props.name;
      }
      // load user photo on navigate
      if(this.user_props.profilePhotoStoragePath) {
        this.loadUserPhoto();
      }
      if(this.user_props.badge_level || this.user_props.badge_level==0) {
        this.loadBadges();
      }
      if(this.user_props.primary_location) {
        this.loadCustomerUser();
      }
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

// mobile & admin site styles
h3 + p {
  margin-bottom: 30px;
}
.photo-container {
  background-color: fadeout(@color-blue, 50%);
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  padding: 15px;
}
.photo-preview {
  position: relative;

  .btn-remove {
    position: absolute;
    top: 0;
    right: 0;
    background-color: fadeout(@light-color, 50%);
    z-index: 100
  }
  .img-profile {
    position: relative;
  }
  .img-profile img.img-fluid {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.form-check {
  margin: 10px 0 0;
}
.form-control-file::file-selector-button {
  visibility: hidden;
}
.form-control-file:before {
  content: 'Upload Photo';
  padding: 0;
  background: none;
  text-decoration: underline;
  color: @dark-color;
  border: none;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  text-align: center;
}
// mobile only
.page {
  padding-top: 30px;
}
.col-photo-container {
  margin-bottom: 10px;
}
.provider-stats {
  margin-bottom: 50px;
}
</style>
