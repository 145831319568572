import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FirebaseApp from './FirebaseApp'
import moment from 'moment'
import './registerServiceWorker'
var sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl
import * as Sentry from "@sentry/vue";

Vue.prototype.$sanitizeUrl = sanitizeUrl
Vue.config.productionTip = false
Vue.use(FirebaseApp)

// init Sentry.io bug tracking
let sentryEnv,
    sentryReplaySessionRate,
    sentryReplayErrorSessionRate,
    sentryTracesRate;
if(process.env.NODE_ENV === "production") {
  sentryEnv = "production";
  sentryReplaySessionRate = 0.1;
  sentryReplayErrorSessionRate = 1.0;
  sentryTracesRate = 0.1;
}
if(process.env.NODE_ENV === "development" || process.env.VUE_APP_ENV === "test") {
  sentryEnv = "development";
  sentryReplaySessionRate = 0;
  sentryReplayErrorSessionRate = 0;
  sentryTracesRate = 0;
}
// must be last because staging also uses NODE_ENV==production
if(process.env.VUE_APP_ENV === "staging") {
  sentryEnv = "staging";
  sentryReplaySessionRate = 1.0;
  sentryReplayErrorSessionRate = 1.0;
  sentryTracesRate = 1.0;
}
if(sentryEnv && sentryReplaySessionRate && sentryTracesRate) {
  Sentry.init({
    Vue,
    environment: sentryEnv,
    dsn: "https://2476495e4e15802611754619201b4762@o4509034352148480.ingest.us.sentry.io/4509034452221952",
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.vueIntegration({
        tracingOptions: {
          trackComponents: true,
        },
      }),
    ],
    tracesSampleRate: sentryTracesRate,
    replaysSessionSampleRate: sentryReplaySessionRate,
    replaysOnErrorSampleRate: sentryReplayErrorSessionRate,
  });
}

//  set up filters
Vue.filter('prettyPrice', (price) => {
  if(price) {
    return "$" + (price/100).toString();
  }
  return "";
})
Vue.filter('prettyDate', (timestamp) => {
  if(timestamp) {
    return moment.unix(timestamp).format("MMM Do, YYYY");
  }
  return "";
})
Vue.filter('prettyDateShort', (timestamp) => {
  if(timestamp) {
    return moment.unix(timestamp).format("M/D/YY");
  }
  return "";
})
Vue.filter('prettyDateTime', (timestamp) => {
  if(timestamp) {
    return moment.unix(timestamp).format("MMM Do, YYYY, h:mm:ssa Z");
  }
  return "";
})
Vue.filter('prettyDateDayMo', (timestamp) => {
  if(timestamp) {
    return moment.unix(timestamp).format("M/D");
  }
  return "";
})
Vue.filter('prettyDateMoName', (timestamp) => {
  if(timestamp) {
    return moment.unix(timestamp).format("MMM");
  }
  return "";
})
Vue.filter('formatDate', (datecode) => {
  if(datecode) {
    // format datecode like 20230906
    let year = datecode.slice(0,4);
    let month = datecode.slice(4,6).replace(/0(.)/,'$1');
    let day = datecode.slice(6).replace(/0(.)/,'$1');
    return `${month}/${day}/${year}`
  }
  return "";
})
Vue.filter('limitStringLength', (str,length) => {
  if(str && length) {
    if(str.length > length) {
      return str.slice(0,length) + '...';
    }
    return str;
  }
  return "";
})
Vue.filter('limitDecimals', (num,length) => {
  if(length) {
    return Number(num).toFixed(length);
  }
  return Number(num).toFixed(0);
})
// find user before creating app, if they are logged in when page refreshed
// wrap Vue app in firebase onAuthStateChanged to check for user before creating app
Vue.auth.onAuthStateChanged((user) => {
  new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    created () {
      if(user) {
        store.dispatch('updateUser', user)
      }
    }
  })
})
